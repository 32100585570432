<template>
    <div>
        <!-- MODALS ================================================= = -->
        <modal name="my-modal" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <!-- Title -->
                        <h4 class="card-header-title">
                            Tambah Gelomabang PPDB
                        </h4>
                        <!-- Close -->
                        <button type="button" class="close" @click="hide_modal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="submitForm" enctype="multipart/form-data">
                        <div class="card-body">
                            <div class="form-group">
                                <div class="row align-items-center">
                                    <div class="col-4">
                                        <label>Kode Gelombang<span class="text-primary">*</span></label>
                                        <input type="text" :readonly="readonlyDisableKode" v-model="form.kode_gelombang" maxlength="3" minlength="3" class="form-control" onkeyup="this.value = this.value.toUpperCase()">
                                    </div>
                                    <div class="col-8">
                                        <label>Nama Gelombang <span class="text-primary">*</span></label>
                                        <input type="text" v-model="form.nama_gelombang" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" v-show="showSelectPeriode">
                                <label>Pilih Periode <span class="text-danger">*</span></label>
                                <select v-model="form.periode_id" class="form-control">
                                    <option v-for="(periode, index) in dataPeriode" :key="index" :value="periode.id">
                                        {{ periode.nama_periode }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>Masa Aktif gelombang <span class="text-danger">*</span></label>
                                <div class="d-flex align-items-center">
                                    <div class="flex-1">
                                        <input type="date" v-model="form.start_date" class="form-control">
                                    </div>
                                    <div class="mx-3">
                                        s/d
                                    </div>
                                    <div class="flex-1">
                                        <input type="date" v-model="form.end_date" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer border-top-0">
                            <ul class="pagination mb-0"></ul>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-text text-muted" @click="hide_modal()">Batal & Tutup</button>
                                <button type="submit" class="btn btn-primary">Simpan & Buat Gelomabang</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>
        <!-- MAIN CONTENT ================================================= = -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <!-- HEADER -->
                <div class="d-block pt-3 mb-4">
                    <div class="row align-items-center">
                        <div class="col">
                            <h1 class="header-title">
                                Master Gelombang PPDB
                                <small class="form-text text-muted mt-2">
                                    Menu ini Digunakan Untuk Mengelola Gelombang PPDB
                                </small>
                            </h1>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-primary ml-2" @click="show_modal()">
                                <div class="d-flex align-items-center">
                                    <i class="fe fe-plus mr-2"></i>Tambah Gelombang
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Card -->
                <div class="card shadow-sm" id="lists" data-toggle="lists" data-options='{"valueNames": ["name"], "page": 6, "pagination": true}'>
                    <div class="card-header">
                        <div class="input-group input-group-flush input-group-merge">
                            <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData()" placeholder="Cari gelombang...">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <span class="fe fe-search"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul class="list-group list-group-flush list" v-if="fetching">
                        <li class="list-group-item p-4">
                            <div class="d-block">
                                <div class="row align-items-center mb-2 row-col row-col-invert">
                                    <div class="col">
                                        <div class="alert alert-primary" role="alert">
                                            Sedang mengambil data..
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul class="list-group list-group-flush list" v-if="!fetching">
                        <li class="list-group-item p-4" v-for="(gelombang, index) in dataGelombang" :key="index">
                            <div class="d-block">
                                <div class="row align-items-center mb-2 row-col row-col-invert">
                                    <div class="col">
                                        <div class="d-flex align-items-center">
                                            <span class="badge badge-primary mr-3 mb-3">Kode Gelombang : {{ gelombang.kode_gelombang }}</span>
                                            <span class="badge badge-info mr-3 mb-3">Periode : {{ gelombang.nama_periode }}</span>
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <div class="btn-group">
                                            <router-link :to="{ name: 'master.gelombang.detail', params: { id: gelombang.id } }" class="btn btn-sm btn-white">
                                                <i class="fe fe-eye mr-2"></i>Setup & Lihat Formulir
                                            </router-link>
                                            <button @click.prevent="editForm(gelombang.id)" type="button" class="btn btn-sm btn-white">
                                                <i class="fe fe-edit mr-2"></i>Ubah
                                            </button>
                                            <button @click.prevent="postDelete(gelombang.id)" class="btn btn-sm btn-white">
                                                <i class="fe fe-trash-2 mr-2"></i>Hapus
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-block">
                                    <!-- Title -->
                                    <h2 class="mb-3 name">
                                        {{ gelombang.nama_gelombang }}
                                    </h2>
                                    <div class="d-flex align-items-center mb-3 flex-wrap">
                                        <span class="badge badge-light mr-3 mb-3">Mulai: {{ gelombang.start_date }}</span>
                                        <span class="badge badge-light mr-3 mb-3">Selesai: {{ gelombang.end_date }}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="card-footer border-top-0">
                        <div style="float:right">
                            <!-- <ul class="pagination mb-0"></ul> -->
                            <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    export default {
        data() {
            return {
                baseUrl: this.$apiconfig,
                dataGelombang: [],
                fetching: true,
                form: {
                    id: '',
                    periode_id: '',
                    kode_gelombang: '',
                    nama_gelombang: '',
                    start_date: '',
                    end_date: '',
                    nama_periode: ''
                },
                dataPeriode: [],
                showSelectPeriode: true,
                readonlyDisableKode: false,
                pagination: {
                    currentPage: 1,
                    totalPages: 0,
                    limit: 5,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: ''
            }
        },
        created() {
            this.getData();
        },
        components: {
            vPagination
        },
        methods: {
            show_modal() {
                this.showSelectPeriode = true;
                this.form.id = '';
                this.form.periode_id = '';
                this.form.kode_gelombang = '';
                this.form.nama_gelombang = '';
                this.form.start_date = '';
                this.form.end_date = '';
                this.readonlyDisableKode = false,
                    this.$modal.show('my-modal');
            },
            hide_modal() {
                this.$modal.hide('my-modal')
            },
            // Get data Gelombang
            getData() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/master/gelombang', {
                        params: {
                            page: this.pagination.currentPage,
                            keywords: this.keywords,
                        }
                    })
                    .then((response) => {
                        //assign state gelombang with response data
                        this.dataGelombang = response.data.data;
                        this.fetching = false;
                        this.pagination.totalPages = response.data.total_page;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                this.$http.get(this.baseUrl + 'admin/master/periode')
                    .then((response) => {
                        this.dataPeriode = response.data.data;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                this.fetching = true;
            },
            // Save data Gelombang
            submitForm() {
                if (this.form.periode_id.length == 0 || this.form.kode_gelombang.length == 0 || this.form.nama_gelombang.length == 0 || this.form.start_date.length == 0 || this.form.end_date.length == 0) {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'inputan tidak boleh kosong',
                    })
                } else if (this.form.kode_gelombang.length != 3) {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Kode Formulir harus 3 digit',
                    })
                } else {
                    this.$http.post(this.baseUrl + 'admin/master/gelombang/save', this.form)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: "success",
                                text: 'Data gelombang berhasil disimpan',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.$modal.hide('my-modal')
                            this.getData();
                            this.form.id = '';
                            this.form.kode_gelombang = '';
                            this.form.nama_gelombang = '';
                            this.form.start_date = '';
                            this.form.end_date = '';
                        })
                        .catch(error => {
                            console.log(error.response.data)
                        });
                }
            },
            // Edit data Formulir
            editForm(id) {
                this.$http.get(this.baseUrl + `admin/master/gelombang/detail?id=${id}`)
                    .then((response) => {
                        if (response.data.status_pemakaian != 'digunakan') {
                            this.showSelectPeriode = false;
                            this.readonlyDisableKode = true;
                        } else {
                            this.showSelectPeriode = true;
                            this.readonlyDisableKode = false;
                        }
                        //assign state periode with response data
                        this.form.id = response.data.data[0].id;
                        this.form.periode_id = response.data.data[0].periode_id;
                        this.form.kode_gelombang = response.data.data[0].kode_gelombang;
                        this.form.nama_gelombang = response.data.data[0].nama_gelombang;
                        this.form.start_date = response.data.data[0].start_date;
                        this.form.end_date = response.data.data[0].end_date;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
                this.$modal.show('my-modal');
            },
            // Delete data Gelombang
            postDelete(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$swal({
                    title: 'Apakah kamu yakin?',
                    html: 'Data yang dihapus tidak dapat di kembalikan',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.baseUrl + 'admin/master/gelombang/delete',
                            method: 'post',
                            data: fd
                        }).then((response) => {
                            if (response.data.status_pemakaian != 'digunakan') {
                                this.$swal(
                                    'Deleted!',
                                    'Data deleted successfully.',
                                    'success'
                                )
                            } else {
                                this.$swal(
                                    'Tidak dapat di hapus!',
                                    'Periode sudah digunakan.',
                                    'info'
                                )
                            }
                            this.getData();
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>